// Here you can add other styles
html {
  scroll-behavior: smooth;
}

.capitalize {
  text-transform: capitalize;
}

.nav-title {
  background: rgba(0, 0, 0, 0.2);
  border-top: 1px solid rgba(240, 240, 240, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.app-header .navbar-brand {
  left: 50px;
  margin-left: 0;
}

.Toastify__toast-body{
  word-break: break-all;
}

// custom form controls
.form-control.app-switch {
  align-items: center;
  padding: 4px 0px;
  border: none;
}

.form-control.react-daterange-picker {
  height: auto;
  min-height: calc(1.5em + 0.75rem + 2px);
  padding: 3px 12px;
  align-items: center;
  &.is-invalid {
    padding-right: 30px;
  }
  .react-daterange-picker__wrapper {
    border: none;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    .react-daterange-picker__inputGroup {
      justify-content: center;
    }
    .react-daterange-picker__range-divider {
      margin: 0 5px;
    }
    .react-daterange-picker__button {
      padding: 0;
      margin-left: 5px;
    }
  }
  &.single_date {
    .react-daterange-picker__wrapper {
      .react-daterange-picker__inputGroup:last-of-type {
        display: none;
      }
      .react-daterange-picker__range-divider {
        display: none;
      }
    }
  }
}

.form-control.toggle-selection {
  padding: 0;
  align-items: center;
  input {
    display: none;
  }
}

.form-control.drag-drop-context-wrapper {
  height: auto;
  .column-title {
    text-align: center;
  }
  .row-droppable {
    align-items: stretch;
    padding: 5px 0;
  }
  .droppable {
    height: calc(100% - 21px);
    min-height: 70px;
    background-color: transparent;
    border: 1px solid $light;
    border-radius: 3px;
    box-sizing: border-box;
    position: relative;
    .empty-placeholder {
      text-align: center;
      position: absolute;
      width: 100%;
      top: calc(50% - 11px);
    }
    &.isDraggingOver {
      background-color: $light;
      border: 1px dashed $secondary;
    }
    .draggable-item {
      margin: 2px 0 3px 0;
      .draggable-item-content {
        border-radius: 2px;
        background-color: $secondary;
        color: $white;
        margin: 5px;
        padding: 7px 13px;
        text-align: center;
      }
    }
    .placeholder-wrapper {
      div {
        margin: 5px 0 !important;
      }
    }
    &.droppable-selected {
      .draggable-item {
        .draggable-item-content {
          background-color: $info;
        }
      }
    }
  }
}

.form-control.tags-field-wrapper {
  height: auto;
  padding-bottom: 0;
  &.react-tagsinput--focused {
    @extend .form-control:focus;
  }
  .react-tagsinput-tag {
    word-break: break-word;
    background-color: #fff;
    border-color: #e4e7ea;
    color: #5c6873;
    &.is-invalid {
      border-color: $danger;
    }
    .react-tagsinput-remove:before {
      content: none;
    }
    .react-tagsinput-remove i {
      margin-left: 5px;
      color: $danger;
    }
  }
  .react-tagsinput-input {
    width: 100%;
  }
  .invalid-feedback {
    display: block;
  }
}

.card .card-body {
  position: relative;
  .empty-placeholder {
    text-align: center;
    position: absolute;
    width: 100%;
    top: calc(50% - 11px);
  }
}
